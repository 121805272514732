<template>
  <div class="home">
      <generic-bg-color></generic-bg-color>
      <!-- First Banner -->
      <div class="first-banner" id="first_banner">
          <!-- Content -->
          <div class="content">
          <!-- Image -->
            <div class="image">
                <div
                    data-aos="fade-right"
                    data-aos-delay="300"
                    data-aos-duration="500" 
                    class="red-box"></div>
                <div
                    data-aos="fade-right"
                    data-aos-dutation="1000"
                    data-aos-delay="600"  
                    class="container-image">

                </div>
            </div>
            <!-- Text -->
            <div class="text">
                <div
                    data-aos="fade-right"
                    data-aos-duration="700"  
                    data-aos-delay="300"
                    class="container-logo">
                    <img src="@/assets/logo-industrias-pico.png" class="logo" alt="Industrias Pico">
                </div>
                <div class="home-title">
                    <home-title></home-title>
                </div>
                <!-- <p class="description">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, quidem perspiciatis 
                    consectetur voluptatibus numquam facere aperiam magni officiis error vel sunt sapiente accusamus,
                    delectus laborum accusantium qui aliquam omnis. Hic deleniti asperiores ab odit soluta iusto ad
                    eius accusantium.
                </p> -->
                <div
                    data-aos="fade-right"
                    data-aos-duration="700" 
                    data-aos-delay="300"
                    class="continue-read"
                    @click="goToServices()">
                    <p class="text-read">
                        <span>S</span>
                        <span>E</span>
                        <span>G</span>
                        <span>U</span>
                        <span>I</span>
                        <span>R</span>
                        <span>&nbsp;</span>
                        <span>L</span>
                        <span>E</span>
                        <span>Y</span>
                        <span>E</span>
                        <span>N</span>
                        <span>D</span>
                        <span>O</span>
                    </p>
                    <span class="material-icons arrow">arrow_downward</span>
                </div>
            </div>
          </div>
      </div>
      <!-- Options -->
      <boxes-services
        ref="services"
        id="container"
        :data_box="data_box">
      </boxes-services>
      <!-- Gallery Generics -->
      <!-- <gallery-generic>
      </gallery-generic> -->
      <!-- Footer -->
      <main-footer></main-footer>
  </div>
</template>

<script>
import HomeTitleComponent from '@/components/HomeTitleComponent'

export default {
	metaInfo: {
		title: 'Industrias pico | Muebles para Oficina en Bucaramanga | Escritorios para Oficinas | Sillas para Oficinas | Divisiones para Oficinas | Divisiones en Vidrio Bucaramanga',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{ name: 'keywords', content:'industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Industrias pico | Muebles para Oficina en Bucaramanga | Escritorios para Oficinas | Sillas para Oficinas | Divisiones para Oficinas | Divisiones en Vidrio Bucaramanga'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card  
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Industrias pico | Muebles para Oficina en Bucaramanga | Escritorios para Oficinas | Sillas para Oficinas | Divisiones para Oficinas | Divisiones en Vidrio Bucaramanga' },
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Industrias pico | Muebles para Oficina en Bucaramanga | Escritorios para Oficinas | Sillas para Oficinas | Divisiones para Oficinas | Divisiones en Vidrio Bucaramanga' },
			{itemprop: 'description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
	},
  components: {
      'home-title': HomeTitleComponent
  },
  data() {
      return {
        data_box: {
            title: 'Nuestros Productos',
            description: `
                Nuestra compañía está 100% comprometida con brindarle a cada uno de nuestros clientes
                productos de alta calidad. Entendemos que es necesario generar soluciones integrales 
                adaptadas a la necesidad de cada cliente brindando atención personalizada.
            `,
            box_1: {
                title: 'Estantería',
                url_image: 'estanterias-metalicas/pesada/estanterias-5.png',
                name_link: 'metal-shelves'
            },
            box_2: {
                title: 'Oficina',
                url_image: 'mobiliario-de-oficina/mobiliario-1.jpg',
                name_link: 'office-furniture'
            },
            box_3: {
                title: 'Supermercado',
                url_image: 'mobiliario-de-supermercado/gondolas/productos/isla/isla_1.jpg',
                name_link: 'supermarket-furniture'
            }
        } 
      }
   },
   methods: {
       goToServices() {
           let container = this.$el.querySelector("#container");
            window.scrollTo(0, 1000)
       }
   }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.home {
    // First Banner
    .first-banner {
        height: 610px;

        // Content
        .content {
            position: absolute;
            top: 0;
            width: 100%;
            height: 410px;
            // border: 2px solid orange;

            // Image
            .image {
                // border: 1px solid green;
                position: relative;
                height: 410px;

                // Red box
                .red-box {
                    width: 100%;
                    height: 100%;
                    background-color: $main-red-1
                }
                // Container image
                .container-image {
                    width: 100%;
                    height: 100%;
                    margin-left: -55px;
                    position: absolute;
                    top: 0;
                    background-image: url(~@/assets/images/home/bg_home_3.jpg);
                    background-size: cover;
                    background-position: center center
                }

                @media (min-width: 760px) {
                    height: 500px
                }
                @media (min-width: 1024px) {
                    width: 60%;
                    height: 588px;
                    display: flex;
                    align-items: flex-end;
                    overflow: hidden;

                     // Red box
                    .red-box {
                        position: relative;
                        width: 100%;
                        height: 80%
                    }
                    // Container image
                    .container-image {
                        width: 93%
                    }
                }
                @media (min-width: 1280px) {
                    width: 58%;
                    // height: 80vh;
                    min-height: 820px;
                    right: 0;
                    position: absolute;
                    top: 0;
                }
                @media (min-width: 1900px) {
                    width: 58%;
                    height: 92vh;
                    right: 0;
                    margin-right: 35px;

                     // Red box
                    .red-box { height: 90% }
                    // Container image
                    .container-image {
                        margin-left: -95px
                    }
                }
            }
            // Text
            .text {
                position: relative;
                padding: 35px 0px 35px 0px;

                .container-logo {
                    text-align: center;
                    .logo {  width: 130px }
                }

                .home-title { padding: 10px 0px 10px 0px }

                .description {
                    padding: 0px 15px 0px 15px;
                    font-size: 0.95rem 
                }

                .continue-read { display: none }

                @media (min-width: 1024px) {
                    width: 35%;
                    .container-logo { margin-bottom: 1.5rem }
                    .description { padding: 0px }
                    .continue-read {
                        display: block;
                        width: 30px;
                        // position: absolute;
                        float: right;
                        margin-top: 35px;
                        right: 0;
                        bottom: 0;
                        margin-bottom: -5rem;
                        margin-right: 0rem;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        cursor: pointer;

                        .text-read {
                            color: $main-red-1;
                            font-size: 0.7rem;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            span { margin-bottom: -3px }
                        }
                        .arrow {
                            color: #FFFFFF;
                            font-size: 1.6rem;
                            padding: 6px;
                            background-color: $main-red-1;
                            border-radius: 50%
                        }
                    }
                }

                @media (min-width: 1280px) {
                    // border: 1px solid red;
                    width: 38%;
                    padding-top: 0px;

                    .container-logo {
                        .logo {  width: 160px }
                        margin-bottom: 0px;
                        margin-top: 20px
                    } 

                    .home-title { 
                        @include transform(scale(1.1) translate(0px, 50px))
                    }
                    
                    .continue-read {
                        // margin-right: 2rem;
                        // margin-bottom: 0rem;
                        margin-top: 70px;
                        .text-read { font-size: 0.9rem }
                    }
                }

                @media (min-width: 1900px) {
                    .home-title { 
                        // @include transform(scale(1))
                        @include transform(translate(0px, 40px))
                    }  
                    .continue-read {
                        margin-top: 30px
                    }
                    .container-logo {
                        .logo {  width: 180px }
                        margin-bottom: 0px;
                        margin-top: 20px
                    }   
                }
            }   

            @media (min-width: 1024px) {
                height: 500px;
                position: relative;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between
            }
            @media (min-width: 1280px) {
                // height: 80vh;
                margin-top: -20px;
                flex-direction: row;
                min-height: 780px;
                position: inherit
            }
        }

        @media (min-width: 500px) {
            height: 720px;
        }

        @media (min-width: 1024px) {
            // height: 95vh;
            padding: 35px
        }

        @media (min-width: 1280px) {
            height: 950px
        }
    }
}
</style>

